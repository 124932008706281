<template>
  <v-container fluid>
    <!-- le workflow applicatif -->
    <div class="d-flex justify-center" flat tile>
      <Workflow
        width="600"
        height="180"
        :steps="workflowSteps"
        :currentStep="workflowIndex"
        :labelWidth="200"
        :lineWidth="140"
      ></Workflow>
    </div>

    <v-row justify="center">
      <v-col xl="6" lg="8" md="8" sm="10" xs="12">
        <!-- le titre et le bouton retour -->
        <div class="d-flex justify-center">
          <TitleAndReturnComponent title="Activité" />
        </div>

        <!-- la barre de progression -->
        <v-row justify="center">
          <v-col xl="8" lg="9" md="10" sm="11" xs="12">
            <!-- la progess bar à afficher lors du chargement des données -->
            <v-progress-linear
              indeterminate
              :active="loading"
            ></v-progress-linear>
          </v-col>
        </v-row>

        <!-- le détail du service -->
        <DetailViewBoxComponent title="Détail d'une activité">
          <template v-slot:items>
            <InputFieldComponent label="Nom" :value="name" />
            <InputFieldComponent label="Métier" :value="metier" />
            <InputFieldComponent label="Nom digital" :value="digitalName" />
            <InputFieldComponent label="Nom complet" :value="fullName" />
            <InputFieldComponent label="Libellé" :value="label" />
            <InputFieldComponent label="Slug" :value="slug" />

            <!-- Les dops -->
            <InputFieldComponent label="DOP - Périmètre" alignLabel="start">
              <template v-slot:value>
                <div v-for="(dop, i) in dops" :key="i">
                  <v-row
                    no-gutters
                    align="center"
                    justify="space-between"
                    class="mb-1"
                  >
                    <v-col cols="7">
                      <div v-if="dop.error" class="red--text" v-html="dop.label"></div>
                      <div v-else>{{ dop.label }}</div>
                    </v-col>
                    <v-col cols="5">
                      <div v-if="dop.error" class="red--text">
                        {{ dop.perimeter }}
                      </div>
                      <div v-else>{{ dop.perimeter }}</div>
                    </v-col>
                  </v-row>
                </div>
              </template>
            </InputFieldComponent>

            <!-- Les sous-activités -->
            <InputFieldComponent
              label="Sous-activités"
              alignLabel="start"
              :divider="false"
            >
              <template v-slot:value>
                <div v-for="(sub, i) in subActivities" :key="i">
                  <div>{{ sub.digitalName }}</div>
                </div>
              </template>
            </InputFieldComponent>
          </template>
        </DetailViewBoxComponent>
      </v-col>
    </v-row>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >
      <span v-html="snackbarMessage"></span>
      </v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import DetailViewBoxComponent from "@/components/ui/DetailViewBoxComponent.vue";
import InputFieldComponent from "@/components/ui/InputFieldComponent.vue";
import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";

import { AppConsulterModifierService } from "@/service/conf/app_consulter_modifier_service.js";
import { ActivityService } from "@/service/sfr/activity_service.js";
import { MetierService } from "@/service/sfr/metier_service.js";
import { UsersService } from "@/service/user/dg_user_service.js";
import { ViewActivityService } from "./ViewActivityService.js";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";
import * as exceptions from "@/service/exception_to_message.js";


export default {
  name: "DetailsActivity",
  components: {
    Workflow,
    TitleAndReturnComponent,
    DetailViewBoxComponent,
    InputFieldComponent,
  },
  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /** le service de chargement des dictionnaires. */
      activitiesService: null,
      /**le service d'accès aux métiers */
      metiersService: null,
      /** le service d'accès aux users */
      serviceUsers: null,
      /** le service d'accès au datas de conf */
      serviceConf: null,

      /** service métier de cette vue */
      viewActivityService: null,

      /**l'identifiant du motif maladie à visualiser. */
      activityId: null,

      /**Afficher le bouton de retour */
      showBackButton: false,

      /** le nom  */
      name: null,
      /** le métier associé */
      metier: null,
      /** le nom digital */
      digitalName: null,
      /** le nom complet  */
      fullName: null,
      /** le libellé */
      label: null,
      /** le slug */
      slug: null,
      /** la liste des dops associés */
      dops: null,
      /** le liste des sous-activités */
      subActivities: null,

      idFunctionDop: null,
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        this.fields = [];

        // Récupération de l'id de la fonction dop
        this.idFunctionDop = await this.serviceConf.getIdDop();

        //Récupération de l'item par son id
        let activity = await this.activitiesService.getActivityById(
          this.activityId
        );

        // Récupération des métiers
        let metiers = await this.metiersService.getAll();
        let metier = metiers.find((m) => m.id === activity.metier);
        
        // Récupération des dops de l'activité
        let loadedDops = await this.viewActivityService.loadDops(this.activityId, this.idFunctionDop.id);
        let dopsOfActivity = loadedDops.allDops;

        let errorMessage = this.viewActivityService.getDopErrorMessage(loadedDops.dopsWithNoMoreDopFunction, loadedDops.dopsOut);
        if (errorMessage) {
          this.addErrorToSnackbar(errorMessage)
        }

        // Récupération des sous-activités
        let subActivities =
          await this.activitiesService.getSubActivitiesOfActivity(
            this.activityId
          );

        /** Init des datas */
        this.name = activity.name || "-";
        this.metier = metier.name || "-";
        this.digitalName = activity.digitalName || "-";
        this.fullName = activity.fullName || "-";
        this.label = activity.label || "-";
        this.slug = activity.slug || "-";
        this.dops = dopsOfActivity;
        this.subActivities = subActivities;
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {},
  mounted() {
    // Instanciation de la classe service
    this.activitiesService = new ActivityService(this.$api);
    this.metiersService = new MetierService(this.$api.getMetierApi());
    this.serviceUsers = new UsersService(this.$api.getUsersApi());
    this.serviceConf = new AppConsulterModifierService(
      this.$api.getAppConsulterModifierApi()
    );
    this.viewActivityService = new ViewActivityService(this.activitiesService, this.serviceUsers);

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.activityId = this.$route.params.id;
    }

    //on affiche le bouton retour
    this.showBackButton = true;

    this.initTableViewEditWorkflow(this.canEdit, 1);

    this.load();
  },
};
</script>
